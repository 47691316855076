<template>
  <MemberForm />
</template>

<script>
import MemberForm from "../components/MemberForm.vue";
export default {
  components: { MemberForm },
  data() {
    return {
      firstname: "",
      lastname: "",
    };
  },
};
</script>
