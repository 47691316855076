import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Privacy from '../views/Privacy.vue';
//import Faq from '../views/Faq.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'not-logged-in' },
    component: Home,
  },
  // FAQ route removed until text provided
  // {
  //   path: '/faq',
  //   name: 'Faq',
  //   meta: { layout: 'not-logged-in' },
  //   component: Faq,
  // },
  {
    path: '/privacy',
    name: 'Policy',
    meta: { layout: 'not-logged-in' },
    component: Privacy,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
