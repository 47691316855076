import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import NotLoggedIn from './layouts/NotLoggedIn.vue';
import { i18n } from '@/plugins/i18n';
Vue.component('not-logged-in-layout', NotLoggedIn);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
