<template>
  <v-container>
    <v-row>
      <v-col class="mx-auto pt-0">
        <h1 class="text-h5 text-center">
          {{ $t("title") }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-card flat min width="450" :loading="loading">
        <v-form ref="form" v-model="valid">
          <v-card-title class="text-center title text-caption pt-2 pb-1">
            {{ $t("instruction") }}
          </v-card-title>
          <v-col cols="12" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model.trim="member.firstname"
              :rules="nameRules"
              :label="$t('firstname')"
              name="firstname"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model.trim="member.lastname"
              :rules="lastnameRules"
              :label="$t('lastname')"
              name="firstname"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="pa-0 px-sm-2 mb-1">
            <v-menu
              v-model="showDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model.trim="dob"
                  :label="$t('dob')"
                  name="dob"
                  class="mt-3"
                  append-icon="mdi-calendar"
                  clearable
                  :hint="$t('format')"
                  dense
                  :rules="dateRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model.trim="dob"
                @input="showDatepicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model.trim="member.sin"
              :rules="sinRules"
              :counter="3"
              :label="$t('sin')"
              maxlength="3"
              name="sin"
              :type="show ? 'number' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model.trim="member.email"
              :rules="emailRules"
              :label="$t('email')"
              name="email"
              :hint="$t('hint')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model.trim="confirmEmail"
              :rules="emailConfirmRules"
              :label="$t('confirmemail')"
              name="emailConfirm"
              onpaste="return false;"
              ondrop="return false;"
              autocomplete="off"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="pa-0 px-sm-2">
            <v-checkbox
              :rules="[(v) => !!v || $t('pleaseAgree')]"
              dense
              v-model="member.isAgreed"
              :label="$t('agree')"
              name="isAgreed"
              :value="true"
            ></v-checkbox>
          </v-col>

          <v-card-actions>
            <v-row>
              <v-col cols="6" class="pt-1">
                <v-btn
                  :loading="loading"
                  :disabled="!valid"
                  color="info"
                  @click="submit"
                  block
                >
                  {{ $t("submit") }}
                </v-btn>
              </v-col>
              <v-col cols="6" class="pt-1">
                <v-btn outlined color="secondary" @click="reset" block>
                  {{ $t("reset") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-row justify="center" class="mt-2">
            <v-col cols="12" v-show="showMessage">
              <v-card outlined :color="`${status} lighten-5`">
                <v-card-text :class="`text-center`">
                  {{ message }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import MemberService from "../services/MemberService";
export default {
  data() {
    return {
      nameRules: [(v) => !!v || this.$t("firstNameRequired")],
      lastnameRules: [(v) => !!v || this.$t("lastNameRequired")],
      emailRules: [
        (v) => !!v || this.$t("emailRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("emailValid"),
      ],
      sinRules: [
        (v) => !!v || this.$t("digits"),
        (v) => /^(?=.*[0-9])/.test(v) || this.$t("digits"),
        (v) => (v && v.length <= 3) || this.$t("notMoreDigits"),
      ],
      dateRules: [
        (v) => !!v || this.$t("enterDob"),
        (v) =>
          /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(v) ||
          this.$t("validDate"),
      ],
      confirmEmail: "",
      loading: false,
      showDatePicker: false,
      show: false,
      checkbox: false,
      status: "success",
      message: "",
      errorMessage: "",
      dob: "",
      member: {
        firstname: "",
        lastname: "",
        email: "",
        dob: "",
        sin: "",
        isAgreed: false,
        lang: "en",
      },
      valid: true,
      showMessage: false,
    };
  },
  computed: {
    emailConfirmRules() {
      return [(v) => v == this.member.email || this.$t("emailNotMatch")];
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.status = "";
      this.showMessage = false;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async submit() {
      this.loading = true;
      this.showMessage = false;
      this.member.dob = this.dob.replace(/-/g, "");
      this.member.lang = this.$i18n.locale;
      MemberService.sendRequest(this.member)
        .then((res) => {
          this.reset();
          this.loading = false;
          this.success(res.data);
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.response
            ? error.response.data == ""
              ? error.message
              : error.response.data
            : error.message;
          this.error(this.errorMessage);
        });
    },
    success(res) {
      this.message = "";
      if (res.message_EN.includes("Failed")) {
        this.message =
          this.$i18n.locale === "fr" ? res.message_FR : res.message_EN;
        this.status = "red";
        this.showMessage = true;
        return;
      } else if (res.message_EN.includes("exceeded")) {
        this.message =
          this.$i18n.locale === "fr" ? res.message_FR : res.message_EN;
        this.status = "red";
        this.showMessage = true;
        return;
      } else if (res.message_EN.includes("Unable")) {
        this.message = this.$t("failedEmail");
        this.status = "red";
        this.showMessage = true;
        return;
      }
      this.message = this.$t("successSent");
      this.status = "green";
      this.showMessage = true;
    },
    error(err) {
      this.message = err;
      this.status = "red";
      this.showMessage = true;
    },
  },
};
</script>

<style scoped>
.title {
  word-break: break-word;
}
</style>