<template>
  <v-container>
    <h1 class="py-0 mb-2 text-h5 font-weight-bold">Code de protection de la vie privée</h1>
    <v-col>
      <h2 class="text-subtitle-1 mb-2 font-weight-bold">Définition des termes</h2>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">PBAS</h3>
      <p>Prudent Benefits Administration Services Inc, Benchmark Decisions Ltd, et Student Benefits Administrators Inc. (ci-après dénommées collectivement « PBAS », pour faciliter la consultation).</p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">Données de PBAS</h3>
      <p>
        Les données confiées à PBAS dans le but d’administrer l’entreprise au nom des clients de PBAS et des participants à ses régimes, y compris les dossiers financiers, les dossiers des employés ou des participants ou toute autre donnée considérée comme étant privée et confidentielle. La présente politique de confidentialité s’ajoute aux obligations professionnelles et éthiques de PBAS concernant les renseignements personnels confiés à PBAS par ses clients et par ses employés.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">Renseignements personnels</h3>
      <p>Les renseignements personnels sont tous les renseignements factuels ou subjectifs, enregistrés ou non, concernant une personne pouvant être identifiée. En général, les renseignements personnels ne comprennent pas les coordonnées professionnelles, notamment votre nom, votre titre et votre numéro de téléphone au travail. 
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">Agent de protection de la vie privée</h3>
      <p>
        La personne chez PBAS chargée de surveiller l’exécution des pratiques de confidentialité gestion afin d’assurer la conformité générale avec les lois fédérales et provinciales sur la protection de la vie privée. Cela inclut de veiller à ce que tous les employés suivent une formation sur les meilleures pratiques en matière de protection de la vie privée et de s’acquitter de toute obligation de divulgation en vertu des lois applicables en la matière, y compris les atteintes à la vie privée.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">Préambule</h3>
      <p>
        PBAS a toujours reconnu et respecté la protection et la confidentialité des renseignements personnels recueillis dans le cadre de ses activités commerciales quotidiennes. À titre d’engagement supplémentaire, PBAS a créé la présente politique de confidentialité, qui incarne son respect des principes présentés dans la Loi sur la protection des renseignements personnels et les documents électroniques (« LPRPDE ») et s’applique à toutes ses activités. Une copie de la LPRPDE est disponible à <a href="https://www.priv.gc.ca" target="blank" class="text-decoration-none">www.priv.gc.ca</a>.
         PBAS respecte toutes les lois fédérales et provinciales applicables en matière de protection de la vie privée. En outre, le PBAS dispose d’un processus documenté pour signaler un incident d’atteinte aux renseignements en sa possession ou sous sa garde, y compris les renseignements qui ont été transférés à un tiers pour traitement. Cette approche est conforme au Règlement général sur la protection des données (« RGPD »). Une copie du RGPD est disponible à <a href="https://www.gdpr.eu" target="blank" class="text-decoration-none">www.gdpr.eu</a>.
      </p>
      <p>
        Le Commissariat à la protection de la vie privée du Canada (« CPVP ») définit une « atteinte aux mesures de sécurité » comme suit :<br>
        <i>
        Communication non autorisée ou perte de renseignements personnels, ou accès non autorisé à ceux-ci, par suite d’une atteinte aux mesures de sécurité d’une organisation ou du fait que ces mesures n’ont pas été mises en place.
      </i>
      </p>
      
      <p>
        Selon le CPVP, une atteinte à la vie privée est:<br>
        <i>la communication non autorisée ou la perte de renseignements personnels, ou l’accès non autorisé à ceux-ci Des atteintes peuvent se produire lorsque des renseignements personnels sont volés, perdus ou partagés par erreur.</i>
      </p>
      <p>
        Seules les atteintes incluant des renseignements personnels sont visées par la LPRPDE, sur la base d’un test de <i>" risque réel de préjudice grave".</i>
      </p>
      <p>
        <i>
          La loi définit le « préjudice grave » comme comprenant la lésion corporelle, l’humiliation, le dommage à la réputation ou aux relations, la perte financière, le vol d’identité, l’effet négatif sur le dossier de crédit, le dommage aux biens ou leur perte.
        </i>
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">Les dix principes équitables en matière d’information de la LPRPDE</h3>
      <p>
        Les dix principes suivants de la protection de la vie privée sont interreliés et sont fondés sur des pratiques équitables en matière d’information. Ils visent à reconnaître le droit à la protection de la vie privée d’une personne, tout en établissant un équilibre avec la nécessité pour une organisation de recueillir, d’utiliser ou de divulguer les renseignements personnels à des fins d’affaires légitimes.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">1) Responsabilité</h3>
      <p>
        PBAS est responsable de tous les renseignements personnels qui sont en sa possession ou sous son contrôle, y compris tout renseignement personnel transféré à des tiers. PBAS a établi des politiques et des procédures afin de respecter la présente politique de confidentialité. Comme condition d’emploi, tous les employés doivent signer une entente de confidentialité. Chaque fois que PBAS fait appel aux services d’un fournisseur tiers, un accord de confidentialité et de non-divulgation est signé afin d’assurer la mise en place de mesures de protection des renseignements personnels. En plus d’audits réguliers et d’autres procédures en matière de conformité, les employés suivent régulièrement une formation afin d’assurer le respect des normes établies par les lois fédérales et provinciales sur la protection de la vie privée.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">2) Identification des fins de la collecte de renseignements personnels</h3>
      <p>
        Sauf si des fins supplémentaires sont transmises à une personne avant la collecte ou au moment où celle-ci est effectuée, PBAS ne recueillera des renseignements personnels qu’aux fins suivantes:
      <ul>
        <li>pour calculer ou verser une prestation</li>
        <li>à des fins de paie</li>
        <li>pour satisfaire aux exigences en matière de déclaration du gouvernement provincial et du gouvernement fédéral</li>
        <li>pour payer les taxes et respecter le droit civil et criminel</li>
        <li>pour déterminer les coûts d’exploitation futurs</li>
        <li>pour permettre les audits</li>
        <li>pour transférer les données de PBAS vers un nouveau régime de prestations ou un régime de remplacement</li>
      </ul>
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">3) Obtention du consentement</h3>
      <p>
        PBAS s’engage à ne recueillir, utiliser ou divulguer les renseignements personnels que si la personne est au courant et y consent, sauf lorsque la loi l’exige ou le permet. Ces renseignements sont généralement acquis lorsque le formulaire d’adhésion aux avantages sociaux est rempli. Une personne peut consentir à la collecte, à l’utilisation et à la divulgation de renseignements personnels à son sujet explicitement ou par l’entremise d’un représentant autorisé. Celui-ci aurait besoin de l’autorisation écrite de la personne avant de divulguer ses renseignements personnels. Dans le cas d’une personne qui est mineure, gravement malade ou frappée d’incapacité mentale, le consentement d’un tuteur légal ou d’un mandataire peut être obtenu. Sous réserve de certaines restrictions juridiques ou contractuelles et moyennant un préavis raisonnable, une personne peut retirer son consentement à n’importe quel moment. PBAS informera les personnes des conséquences du refus ou du retrait du consentement, lorsqu’elles le demandent. Le refus ou le retrait du consentement pourrait précipiter la destruction des renseignements personnels d’une personne et, par conséquent, rendre impossible la participation continue à un régime de prestations. 
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">4) Limites sur la collecte des renseignements personnels</h3>
      <p>
        PBAS limitera la quantité et le type des renseignements personnels recueillis. PBAS ne recueillera les renseignements personnels qu’aux fins indiquées ou de toute autre manière autorisée par la loi, et elle recueillera les renseignements au sujet d’une personne principalement auprès de celle-ci, ou de sources externes si la personne a consenti à une telle collecte.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">5) Limites sur l’utilisation, la divulgation et la conservation des renseignements personnels</h3>
      <p>
        PBAS utilisera et divulguera les renseignements personnels uniquement pour les raisons pour lesquelles ils ont été recueillis, sauf si une personne consent à ce qu’ils soient utilisés ou divulgués pour d’autres raisons. Dans certaines circonstances, PBAS pourrait avoir le droit ou le devoir légal de divulguer des renseignements personnels sans avoir obtenu de consentement. PBAS ne conservera les renseignements personnels que tant qu’ils seront requis pour les fins indiquées.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">6) Maintien de l’exactitude des renseignements personnels</h3>
      <p>
        PBAS gardera les renseignements personnels qui sont en sa possession ou sous son contrôle exacts, complets, à jour et pertinents, en se fondant sur les renseignements les plus récents dont elle dispose. Une personne peut contester l’exactitude et le caractère complet des renseignements personnels qui les concerne et les faire modifier, au besoin.
      <p>
        Si une personne démontre que des renseignements personnels sont inexacts, incomplets, périmés ou inutiles, PBAS les révisera ou les supprimera, et elle communiquera les renseignements personnels révisés à tout tiers à qui elle avait communiqué des renseignements personnels incorrects ou périmés pour lui permettre de réviser ses dossiers.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">7) Protection des renseignements personnels</h3>
      <p>
      <p>
        PBAS protégera les renseignements personnels avec des mesures de protection qui conviennent à leur niveau de sensibilité.<br>
      
        Le cryptage, les pare-feu, les programmes antivirus et les procédures d’authentification robustes, y compris la mise à jour régulière des mots de passe, sont quelques exemples des mesures de sécurité mises en place.<br>
      
        Des tests de récupération après sinistre sont effectués tous les ans sur un site de récupération éloigné. Dans le cadre de ce test, tous les systèmes sur serveur sont récupérés et vérifiés. La protection de la vie privée est une entente contractuelle que PBAS conclut tous les ans avec l’entreprise qui effectue les tests de récupération après sinistre. 
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">8) Mise à disposition des renseignements concernant les politiques et les procédures</h3>
      <p>
        PBAS sera ouverte au sujet des procédures utilisées pour gérer les renseignements personnels.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">9) Accès aux renseignements personnels</h3>
      <p>
        Lorsqu’une personne le demande, PBAS lui dira quels renseignements personnels la concernant elle a en sa possession ou sous son contrôle, pourquoi ils sont utilisés et à qui ils ont été divulgués. PBAS répondra à la demande au plus tard trente (30) jours après sa réception. Ce délai peut être prolongé d’un maximum de trente (30) jours supplémentaires, si, par exemple, il faut davantage de temps pour effectuer des consultations. Dans un tel cas, PBAS en informerait la personne par écrit. Dans le cas improbable où PBAS détermine que des coûts pourraient devoir être imposés à la personne pour obtenir un tel accès, elle l’informera des coûts permis par la loi pour obtenir un tel accès.
      </p>
      <h3 class="text-subtitle-1 mb-2 font-weight-bold">10) Traitement des plaintes et des questions</h3>
      <p>
        Les plaintes et les questions devraient être adressées par écrit à l’agent de protection de la vie privée à l’adresse suivante:<br>
      </p>
      <p class="ml-4">
        PBAS<br>
        110-61 International Blvd<br>

        Toronto, Ontario M9W 6K4<br>
      </p>
      <p>
        Toutes les plaintes feront l’objet d’une enquête. Si une plainte s’avère justifiée, PBAS prendra les mesures appropriées, y compris la modification de ses politiques et de ses pratiques, le cas échéant. SI une personne n’est pas satisfaite de la façon dont PBAS a répondu à sa plainte ou à sa question, elle peut déposer une plainte écrite à l’adresse suivante:
      </p>
      <p class="ml-4">
        Commissariat à la protection de la vie privée du Canada<br>
        30, rue Victoria<br>
        Gatineau (Québec) K1A 1H3<br>
      </p>
      <h2 class="text-subtitle-1 mb-2 font-weight-bold">Changements à la présente politique de confidentialité </h2>
      <p>
        Pour s’assurer que la présente politique de confidentialité reste à jour, PBAS se réserve le droit de la modifier de temps à autre. Les avis portant sur les changements à la politique de confidentialité privée peuvent être distribués par l’entremise de bulletins, de déclarations ou de bulletins d’information, ou publiés sur le site Web de PBAS.
      </p>
      <br>
      <i>
        Date de révision : 17 juin 2020
      </i>
    </v-col>
  </v-container>
</template>
<script>
export default {};
</script>
<style scoped>
p {
  text-align: justify;
}
p.ml-4 {
  text-indent: 0;
}
</style>