export default {
  title: 'Recherche du numéro de certificat de membre',
  pageTitle: 'Recherche de certificat',
  instruction:
    "Pour demander votre numéro de certificat, veuillez compléter les informations ci-dessous. Vous recevrez une confirmation par courriel. Les informations fournis ci-dessous doivent correspondre aux informations fournis à l'administrateur du régime lors de votre adhésion au régime.",
  firstname: 'Prénom',
  lastname: 'Nom de famille',
  email: 'Courriel',
  hint: "Pour adhérer, vous devez fournir une adresse courriel valide. L'adresse courriel n'est pas rendue publique. Les messages générés par le système seront envoyés à cette adresse courriel. Votre courriel sera utilisé pour confirmer votre numéro de certificat et pour toute communication future par l'administrateur du régime.",
  confirmemail: 'Confirmez votre adresse courriel',
  dob: 'Date de Naissance (AAAA-MM-JJ)',
  format: 'Format AAAA-MM-JJ',
  sin: '3 derniers chiffres du NAS',
  agree:
    "En utilisant ce service, j'accepte de fournir mon adresse courriel pour les communications de l'administrateur du régime.",
  pleaseAgree: 'Doit accepter de continuer',
  assistance:
    "Pour assistance, veuillez communiquer avec l'administrateur du régime.",
  assistanceEmail: 'Courriel : dentaire@pbas.ca',
  assistancePhone: 'Téléphone : 514-856-1779 ou 1-800-361-6915',
  submit: 'Soumettre',
  reset: 'Réinitialiser',
  pleaseConfirm: 'Veuillez confirmer',
  firstNameRequired: 'Le prénom est requis',
  lastNameRequired: 'Le nom de famille est requis',
  emailRequired: 'Un e-mail est requis',
  emailValid: "L'email doit être valide",
  digits: 'Veuillez saisir 3 chiffres',
  notMoreDigits: 'ne doit pas dépasser 3 chiffres',
  enterDob: 'Entrer date de naissance',
  validDate: 'La format de date doit être AAAA-MM-JJ',
  emailNotMatch: "L'adresse courriel ne correspond pas",
  successSent:
    'Numéro de certificat envoyé avec succès. Veuillez vérifier votre courriel',
  errorMessage1:
    'Tentative échouée; Veuillez vérifier vos informations. Vous avez ',
  errorAttempts: " tentative(s) restantes aujourd'hui.",
  errorMessage2:
    'Vous avez atteint la limite de 3 tentatives. Veuillez réessayez dans 24 heures.',
  home: 'Accueil',
  privacy: 'Politique de confidentialité',
  failedEmail: "Incapable d'envoyer des courrier",
};
