import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enTranslations from '../assets/en';
import frTranslations from '../assets/fr';

Vue.use(VueI18n);

const messages = {
  en: enTranslations,
  fr: frTranslations,
};

export const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages,
});
