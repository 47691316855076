<template>
  <div>
    <PrivacyEnglish v-if="this.$i18n.locale === 'en'" />
    <PrivacyFrench v-if="this.$i18n.locale === 'fr'" />
  </div>
</template>
    
<script>
import PrivacyEnglish from "../components/Privacy-english.vue";
import PrivacyFrench from "../components/Privacy-french.vue";
export default {
  components: { PrivacyEnglish, PrivacyFrench },
};
</script>

<style>
</style>