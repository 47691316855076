<template>
  <v-footer app>
    <v-container>
      <v-row justify="center">
        <div class="title text-caption text-center">
          {{ $t("assistance") }}<br />
          {{ $t("assistanceEmail") }}<br />
          {{ $t("assistancePhone") }}
        </div>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {};
</script>

<style>
</style>