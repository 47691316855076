<template>
  <v-card class="overflow-hidden">
    <v-app>
      <v-app-bar app fixed extension-height="30" height="60" flat>
        <v-row>
          <v-col cols="6" align-self="center">
            <a href="https://pbas.ca/">
              <v-img
                v-if="$i18n.locale == 'en'"
                :src="require('@/assets/pbas_group_logo.png')"
                max-height="50"
                width="165"
                contain
                class="float-left mb-1"
              />
              <v-img
                v-if="$i18n.locale == 'fr'"
                :src="require('@/assets/pbas-logo-french.png')"
                max-height="50"
                width="180"
                class="float-left mb-1"
                contain
              />
            </a>
          </v-col>
          <v-col cols="6">
            <v-img
              :src="require('@/assets/logo.png')"
              max-height="50"
              width="180"
              class="float-right mt-1"
              contain
            />
          </v-col>
        </v-row>
        <template v-slot:extension>
          <v-tabs height="25" centered hide-slider show-arrows class="white">
            <v-tab
              class="text-subtitle-2 text-capitalize mr-sm-10 mt-2"
              route
              to="/"
              >{{ $t("home") }}</v-tab
            >
            <!-- FAQ Button hidden until text provided -->
            <!-- <v-tab
              class="text-subtitle-2 text-capitalize mr-sm-10 mt-2"
              route
              to="/faq"
              >FAQ</v-tab
            > -->
            <v-tab
              class="text-subtitle-2 text-capitalize mr-sm-10 mt-2"
              route
              to="/privacy"
              >{{ $t("privacy") }}</v-tab
            >
            <v-tab
              text
              @click="setLocale()"
              active-class="lang-tab"
              class="text-subtitle-2 text-capitalize mt-2"
            >
              {{ localeBtn }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
      <v-divider></v-divider>
      <v-main>
        <v-container>
          <slot />
        </v-container>
      </v-main>
      <Footer />
    </v-app>
  </v-card>
</template>

<script>
import Footer from "../components/Footer.vue";
import GetBrowserLocale from "../util/get-browser-locale";
export default {
  components: { Footer },
  data() {
    return {
      navItems: [
        { title: "Home", path: "/" },
        { title: "Privacy policy", path: "/privacy" },
      ],
      links: [
        {
          text: this.$t("home"),
          route: "/",
        },
        {
          text: this.$t("privacy"),
          route: "/privacy",
        },
      ],
    };
  },
  created() {
    let appLocale = "";

    if (
      window.location.hostname === process.env.VUE_APP_FR_DOMAIN_URL ||
      window.location.hostname == "certificats_test.pbas.i3websphere.ca"
    ) {
      appLocale = "fr";
    } else {
      appLocale = GetBrowserLocale();
    }
    this.$i18n.locale = appLocale;
  },
  methods: {
    setLocale() {
      let nextLocale = this.$i18n.locale === "en" ? "fr" : "en";
      this.$i18n.locale = nextLocale;
    },
  },
  computed: {
    localeBtn() {
      return this.$i18n.locale === "en" ? "Français" : "English";
    },
  },
};
</script>
<style>
.topbar {
  flex: none;
}
html {
  overflow-y: auto;
}
.lang-tab {
  color: grey !important;
}
.v-toolbar__content {
  z-index: 10;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
}
</style>
