export default {
  title: 'Member Certificate Finder',
  pageTitle: 'Certificate Finder',
  instruction:
    'To request your Certificate number please enter information below. You will receive a confirmation by e-mail. The information provided below must match the information provided to the plan administrator following your enrolment in the plan.',
  firstname: 'First name',
  lastname: 'Last name',
  email: 'E-mail',
  hint: 'A valid e-mail address is required to register. The e-mail address provided is not made public. All messages generated from the system will be sent to this e-mail address. Your e-mail will be used to confirm your Certificate number and for future communications from the plan administrator.',
  confirmemail: 'Confirm e-mail',
  dob: 'Date of "Birth (YYYY-MM-DD)',
  format: 'YYYY-MM-DD format',
  sin: 'Last 3 digits of the SIN',
  agree:
    'By using this service, I agree to provide my e-mail address for communications from the plan administrator.',
  pleaseAgree: 'Must agree to continue',
  assistance: 'For assistance, please contact the Plan Administrator.',
  assistanceEmail: 'E-mail: dentaire@pbas.ca',
  assistancePhone: 'Telephone: 514-856-1779 or 1-800-361-6915',
  submit: 'Submit',
  reset: 'Reset',
  pleaseConfirm: 'Please confirm',
  firstNameRequired: 'First name is required',
  lastNameRequired: 'Last name is required',
  emailRequired: 'E-mail is required',
  emailValid: 'E-mail must be valid',
  digits: 'Please enter 3 digits',
  notMoreDigits: 'must be not more than 3 digits',
  enterDob: 'Enter date of birth',
  validDate: 'Date format must be YYYY-MM-DD',
  emailNotMatch: 'E-mail does not match',
  successSent: 'Successfully sent Certificate number. Please check your e-mail',
  errorMessage1: 'Failed attempt; Please check your information. You have ',
  errorAttempts: ' attempt(s) remaining today.',
  errorMessage2: 'You have exceeded 3 attempts. Try again in 24 hours.',
  home: 'Home',
  privacy: 'Privacy policy',
  failedEmail: 'Failed to send Email',
};
