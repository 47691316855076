<template>
  <div id="app">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
const defaultLayout = "not-logged-in";

export default {
  watch: {
    locale: function () {
      document.title = this.$t("pageTitle");
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
};
</script>
